<template>
  <div>
    <h3 v-if="country" class="h5 mb-2" >{{ country }}</h3>
    <p class="text-small">
      <span v-if="name">{{ name }}</span>
      <span v-if="address">{{ address }}</span>
      <a v-if="phone" :href="`tel:${phone}`">{{ phone }}</a>
      <a v-if="email" :href="`mailto:${email}`">{{ email }}</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'HeadquarterResume',

  props: {
    country: {
      type: String
    },
    name: {
      type: String
    },
    address: {
      type: String
    },
    phone: {
      type: String
    },
    email: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  span {
    display: block;
  }

  a {
    display: block;
    color: $color-text;
    text-decoration: none;
  }
</style>
