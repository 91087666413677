<template>
  <div v-cursormove="onCursorMove" class="cursor" :style="style" :class="{'over-target': !!hoverTarget, 'down': mouseDown, 'visible': visible}" >
    <transition>
      <component :is="cursors[hoverCursor] ? cursors[hoverCursor] : cursors.default" :key="hoverCursor" class="cursor-image" />
    </transition>

    <transition>
      <div class="help-text text-script" v-if="hoverCursor === 'drag-horizontal'" :class="{'down': mouseDown}">Click and drag</div>
    </transition>
  </div>
</template>

<script>
import delay from 'lodash/delay'
import throttle from 'lodash/throttle'
import { ElasticNumber, RAF } from '@monogrid/js-utils'

import DeltaTime from '@/mixins/DeltaTime'

import DefaultCursor from '@/assets/img/cursors/regi_star.svg'
// import CameraCursor from '@/assets/img/cursors/camera.svg'
// import PlusCursor from '@/assets/img/cursors/plus.svg'
// import BackCursor from '@/assets/img/cursors/back.svg'
// import ForwardCursor from '@/assets/img/cursors/forward.svg'
// import PreviousCursor from '@/assets/img/cursors/previous.svg'
// import NextCursor from '@/assets/img/cursors/next.svg'
// import ListCursor from '@/assets/img/cursors/list.svg'
// import CalendarCursor from '@/assets/img/cursors/calendar.svg'
// import AsteriskCursor from '@/assets/img/cursors/asterisk.svg'
// import GridCursor from '@/assets/img/cursors/grid.svg'
// import GalleryCursor from '@/assets/img/cursors/gallery.svg'
// import ZoomInCursor from '@/assets/img/cursors/zoom-in.svg'
// import ZoomOutCursor from '@/assets/img/cursors/zoom-out.svg'
// import DragHorizontalCursor from '@/assets/img/cursors/drag-horizontal.svg'
// import CloseCursor from '@/assets/img/cursors/close.svg'
// import ArrowCursor from '@/assets/img/cursors/arrow.svg'
// import FilterCursor from '@/assets/img/cursors/filter-icon.svg'
// import AnimatedEye from '@/atoms/AnimatedEye'

export default {
  name: 'CursorComponent',
  mixins: [DeltaTime],
  data () {
    return {
      visible: false,
      hoverTarget: null,
      mouseDown: false,
      hoverAlignment: null,
      hoverScale: 1,
      hoverRotation: 0,
      hoverCursor: 'default',
      style: {
        transform: 'translate3d(0, 0, 0)'
      },
      cursors: {
        default: DefaultCursor
      }
    }
  },
  created () {
    this.x = new ElasticNumber(0)
    this.x.speed = 10
    this.y = new ElasticNumber(0)
    this.y.speed = 10
    this.scale = new ElasticNumber(0)
    this.scale.speed = 10
    this.rotation = new ElasticNumber(0)
    this.rotation.speed = 10
  },
  mounted () {
    document.addEventListener('mousedown', this.onMouseDown)
    document.addEventListener('mouseup', this.onMouseUp)
    document.addEventListener('mouseleave', this.onMouseUp)
    document.addEventListener('mouseover', this.onMouseOver)
    document.addEventListener('mouseout', this.onMouseOut)
  },
  destroyed () {
    if (this.visible) {
      RAF.remove(this.update)
    }
    document.removeEventListener('mousedown', this.onMouseDown)
    document.removeEventListener('mouseup', this.onMouseUp)
    document.removeEventListener('mouseleave', this.onMouseUp)
    document.removeEventListener('mouseover', this.onMouseOver)
    document.removeEventListener('mouseout', this.onMouseOut)
  },
  watch: {
    visible (isVisible) {
      if (isVisible) {
        RAF.add(this.update)
      } else {
        RAF.remove(this.update)
      }
    }
  },
  methods: {
    onMouseDown () {
      this.mouseDown = true
    },
    onMouseUp () {
      this.mouseDown = false
    },
    onCursorMove (x, y, e) {
      if (e.type === 'mousemove') {
        this.visible = true
      } else {
        this.visible = false
      }
      this.x.target = x
      this.y.target = y
    },
    onMouseOut (e) {
      const dataTarget = this.searchData(e.target)
      if (dataTarget) {
        clearTimeout(this.outDelay)
        this.outDelay = delay(() => {
          this.dataTarget = null
        }, 100)
      }
    },
    onMouseOver (e) {
      if (e.target.tagName === 'IFRAME' && this.visible) {
        setTimeout(() => (this.visible = false), 100)
      }
      const dataTarget = this.searchData(e.target)
      if (dataTarget) {
        clearTimeout(this.outDelay)
        this.dataTarget = dataTarget
      }
    },
    searchData (domElement) {
      if (!domElement) {
        return false
      }
      if (domElement.tagName === 'A' || domElement.tagName === 'BUTTON') {
        return domElement
      }
      if (domElement.dataset && domElement.dataset.cursorTarget !== null && domElement.dataset.cursorTarget !== undefined) {
        return domElement
      }
      if (domElement.parentNode) {
        return this.searchData(domElement.parentNode)
      }
      return false
    },
    setCursorSettings: throttle(function () {
      if (this.dataTarget && this.dataTarget.dataset.cursorTarget && document.body.contains(this.dataTarget)) {
        const rect = this.dataTarget.getBoundingClientRect()
        this.hoverCursor = this.dataTarget.dataset.cursorTarget.toString().split('|')[0] ? this.dataTarget.dataset.cursorTarget.toString().split('|')[0] : 'default'
        this.hoverAlignment = this.dataTarget.dataset.cursorTarget.toString().split('|')[1] ? this.dataTarget.dataset.cursorTarget.toString().split('|')[1].split(' ') : null
        this.hoverScale = this.dataTarget.dataset.cursorTarget.toString().split('|')[2] ? parseFloat(this.dataTarget.dataset.cursorTarget.toString().split('|')[2].split(' ')) : 1
        this.hoverRotation = this.dataTarget.dataset.cursorTarget.toString().split('|')[3] ? parseFloat(this.dataTarget.dataset.cursorTarget.toString().split('|')[3].split(' ')) : 0
        this.hoverTarget = rect
      } else if (this.dataTarget && (this.dataTarget.tagName === 'A' || this.dataTarget.tagName === 'BUTTON')) {
        this.hoverScale = 1.5
        this.hoverRotation = 180
      } else {
        this.dataTarget = null
        this.hoverCursor = 'default'
        this.hoverAlignment = null
        this.hoverScale = 1
        this.hoverRotation = 0
        this.hoverTarget = null
      }
    }, 100),
    update () {
      const delta = this.getDelta()
      if (this.hoverTarget && this.hoverAlignment) {
        this.x.target = this.hoverTarget.x
        this.y.target = this.hoverTarget.y
        switch (this.hoverAlignment[0]) {
          case 'center':
            this.x.target += (this.hoverTarget.width / 2)
            break
          case 'right':
            this.x.target += this.hoverTarget.width
            break
        }
        switch (this.hoverAlignment[1]) {
          case 'center':
            this.y.target += (this.hoverTarget.height / 2)
            break
          case 'bottom':
            this.y.target += this.hoverTarget.height
            break
        }
      }
      this.scale.target = this.hoverScale
      this.rotation.target = this.hoverRotation
      this.setCursorSettings()
      this.x.update(delta)
      this.y.update(delta)
      this.scale.update(delta)
      this.rotation.update(delta)
      this.style = {
        transform: `translate3d(${this.x.value - 38}px, ${this.y.value - 25}px, 0) scale(${this.scale.value}) rotate(${this.rotation.value}deg)`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor {
  pointer-events: none;
  user-select: none;
  backface-visibility: none;
  width: rem(100px);
  height: rem(100px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-cursor;
  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }

  // &.over-target {
  //
  // }
}

.cursor-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: transform 0.25s $ease-out-expo;
  fill: #000;
  width: rem(20px);
  height: rem(20px);

  .down & {
    transform: translate3d(-50%, -50%, 0) scale(0.7, 0.7);
  }
}

.help-text {
  position: absolute;
  top: rem(100px);
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  white-space: nowrap;
  transition: transform 1s $ease-out-expo, opacity 1s $ease-out-expo;

  &.down {
    opacity: 0;
    transform: translate3d(-50%, 0, 0) scale(0.8);
  }
}
</style>
