<template>
  <div>
    <h1 class="step-title" v-html="title"></h1>
    <p class="stagger">Let’s get to know each other. Fill out this form to help us understand your goal. Let’s begin!</p>
    <div class="mt-16 stagger">
      <Button @click="$emit('next')" type="button">
        Start
      </Button>
    </div>
  </div>
</template>

<script>
import Splitting from 'splitting'
import Button from '@/atoms/Button'
export default {
  name: 'Step1',
  components: { Button },
  data () {
    return {
      title: Splitting.html({ content: 'Start a project with us', by: 'chars' })
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  width: 90%;
  margin: rem(16px) auto;
}
</style>
