<template>
  <v-app
    v-show="!$root.platform.prerenderer && isReady"
  >
    <Header/>
    <Menu/>
    <v-main>
      <transition :name="pageTransition" mode="out-in" @after-enter="onEnterDone">
        <router-view v-if="isReady" :key="$route.fullPath" />
      </transition>
    </v-main>
    <Wizard/>
    <BannerCookie />
    <CursorComponent v-if="!$root.platform.mobile" />

    <v-dialog v-model="showAuthError" content-class="error-dialog text-center" max-width="500">
      <div>
        <button class="error-dialog-close" type="button" @click="showAuthError = false"></button>
      </div>
      <h3 class="text-center mb-4">{{$auth.authError}}</h3>
      <router-link
        class="button mb-4 mx-auto"
        :to="{name: 'ForgotPasswordPage'}"
      >
        I forgot my password
        <Arrow/>
      </router-link>
      <div class="d-flex justify-space-between align-center">
        <p class="text-small mb-0">Oh, yeah, I don't have an account...</p>
        <Cta colorSchema="none" action="send-email" label="Write us to get one" />
      </div>
    </v-dialog>

    <VersionInfo
      v-if="$root.process.env.SERVICE_STAGE !== 'production'"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
      />
  </v-app>
</template>

<script>
import { optimizedImageURL } from '@/services/OptimizedImage'
import VersionInfo from '@monogrid/vue-lib/lib/components/VersionInfo'
import GetDefaultSeo from '@/graphql/seo/GetDefaultSeo.gql'
// import shareImage from '@/assets/img/share.jpg'
import Header from '@/organisms/Header'
import Menu from '@/organisms/Menu'
import Wizard from '@/organisms/Wizard'
import Cta from '@/molecules/Cta'
import Arrow from '@/assets/img/cta-arrow.svg'
import CursorComponent from '@/atoms/CursorComponent'
import { menuEvent, startProjectEvent } from '@/constants'
import ActiveStateListenerMixin from '@/mixins/ActiveStateListenerMixin'
import BannerCookie from './atoms/BannerCookie.vue'

const menuListenerMixin = ActiveStateListenerMixin(menuEvent, 'menuIsOpen')
const wizardListenerMixin = ActiveStateListenerMixin(startProjectEvent, 'wizardIsOpen')

let initializedModernizr = false
const HTML = document.getElementsByTagName('html')[0]

export default {
  name: 'App',
  mixins: [menuListenerMixin, wizardListenerMixin],
  components: {
    VersionInfo,
    Header,
    Menu,
    CursorComponent,
    Wizard,
    Cta,
    Arrow,
    BannerCookie
  },
  apollo: {
    DefaultSeo: {
      query: GetDefaultSeo,
      variables: {
        slug: 'globals'
      }
    }
  },
  data () {
    return {
      // this flag is used to show the app once the preloading
      // (if present) is finished
      isReady: false,
      showAuthError: false
    }
  },

  mounted () {
    // disable right click
    document.oncontextmenu = document.body.oncontextmenu = function () { return false }
    // set this.isReady = true
    // when all stuff that needs to be loaded for the app is loaded
    // if you need to preload stuff, delete this line and set to true later
    this.isReady = true
    if (this.$auth.authError) {
      this.showAuthError = true
    }
  },
  updated () {
    // if not prerenderer
    if (!this.$root.platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      // window.Modernizr.addAsyncTest('avif', this.testAvif)
      for (const key in this.$root.platform) {
        window.Modernizr.addTest(key, () => {
          return this.$root.platform[key]
        })
      }
    }
  },
  watch: {
    showAuthError (value) {
      if (!value) this.$auth.authError = null
    },
    '$auth.authError' (value) {
      this.showAuthError = !!value
    },
    '$auth.user' (value) {
      // if user signs out and we are on a restricted page
      if (!value && this.$route.meta.restricted) {
        // this redirects to the home
        this.$router.push({ name: 'Home' })
        // this, on the user logout, redirects to login page with a
        // return to the current page
        // this.$router.push({ name: 'Login', query: { to: this.$route.fullPath } })
      }
    },
    '$route' (to, from) {
      if (from.fullPath !== '/' && to.fullPath !== from.fullPath) {
        this.$root.backRoute = from
      }

      this.$root.preloader.visible = true
      this.showAuthError = false
      HTML.classList[to.name === 'Home' ? 'add' : 'remove']('overflowHidden')
    },
    menuIsOpen () {
      this.overflowState()
    },
    wizardIsOpen () {
      this.overflowState()
    }
    // isReady: function (value) {
    //   // hide / show preloader
    //   this.$root.preloader.visible = !value
    // },
  },
  computed: {
    pageTransition () {
      if (this.$route.name === 'Home' || this.$route.name === 'WeAreRegi') {
        return 'pageFade'
      } else {
        return 'pageSlideUp'
      }
    }
  },
  methods: {
    onEnterDone (el) {
      if (el.classList.contains('page')) {
        this.$events.emit('page-entered')
      }
    },
    overflowState () {
      HTML.classList[this.menuIsOpen || this.wizardIsOpen ? 'add' : 'remove']('overflowHidden')
    }
  },
  metaInfo () {
    if (this.DefaultSeo) {
      const mainTitle = this.DefaultSeo ? this.DefaultSeo.metaTitle : 'REGI Laboratories'

      let meta = [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        // { name: 'twitter:site', content: '@monogridstudio' },
        // { name: 'twitter:creator', content: '@monogridstudio' },
        // generic description
        { vmid: 'description', name: 'description', content: this.DefaultSeo ? this.DefaultSeo.metaDescription : '' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/' + this.$route.fullPath },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: mainTitle },
        { vmid: 'ogdescription', property: 'og:description', content: this.DefaultSeo ? this.DefaultSeo.metaDescription : '' }
      ]

      if (this.DefaultSeo.shareImage) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.DefaultSeo.shareImage, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: '',
        // all titles will be injected into this template
        titleTemplate: (chunk) => chunk ? `${chunk} | ${mainTitle}` : mainTitle,
        base: { href: '/' },
        meta
      }
    }
  }
}
</script>

<style lang="scss">
@import 'swiper/swiper';

@font-face {
  font-family: 'PP Woodland Regular';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('assets/fonts/PPWoodland-Regular.woff2') format('woff2'),
    url('assets/fonts/PPWoodland-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('assets/fonts/Roboto-Light.woff2') format('woff2'),
    url('assets/fonts/Roboto-Light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src:
    url('assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('assets/fonts/Roboto-Regular.woff') format('woff');
}

/* Simple fade */
.pageFade-leave-active {
  transition: opacity 0.3s;
}

.pageFade-enter-active {
  transition: opacity 0s;
}

.pageFade-enter,
.pageFade-leave-to {
  opacity: 0;
}

.pageFade-leave-to {
  transition-delay: 0.3s;
}
/* Fade slide up */
.pageSlideUp-enter-active,
.pageSlideUp-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.pageSlideUp-enter-active {
  transition-delay: 1s;
}

.pageSlideUp-enter,
.pageSlideUp-leave-to {
  opacity: 0;
}

.pageSlideUp-enter {
  transform: translateY(50px);
}

.pageSlideUp-enter-to {
  transform: translateY(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-to {
  transition-delay: 0.3s;
}

.v-main__wrap {
  overflow-y: hidden;
  overflow-x: hidden;
}

html {
  overscroll-behavior: none;
  height: 100%;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// * {
//   user-select: none;
// }
// input,
// textarea,
// label,
// .version-info {
//   user-select: initial;
// }

// html,
// body {
//   height: 100%;
//   width: 100%;
//   overflow-x: hidden;
// }

// .v-application,
// main {
//   min-height: 100%;
//   display: flex;
//   flex: 1 0 auto;
//   flex-direction: column;
// }

.overflowHidden {
  overflow: hidden;

  &.android body {
    height: 100%;
  }
}

.-below {
  position: relative;
  z-index: $z-below;
}

.-above {
  position: relative;
  z-index: $z-above;
}

.-full,
.-fullFixed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.-fullFixed {
  position: fixed;
}

.-cover {
  object-fit: cover;
}

.container {
  padding-top: 0;
  padding-bottom: 0;
}

section {
  position: relative;
  padding: rem(32px 0);
  @include breakpoint('md-and-up') {
    padding: rem(96px 0);
  }
}

// TYPOGRAPHY
body {
  font-family: 'Roboto';
  font-size: rem(16px);
  line-height: rem(24px);
  background: $color-bg;
}

.h1-big {
  font-family: 'PP Woodland Regular';
  font-size: rem(42px);
  line-height: rem(43px);
  color: $color-text-dark;
  @include breakpoint('md-and-up') {
    font-size: rem(99px);
    line-height: rem(97px);
  }
}

h1,
.h1 {
  font-family: 'PP Woodland Regular';
  font-size: rem(42px);
  line-height: rem(43px);
  color: $color-text-dark;
  @include breakpoint('md-and-up') {
    font-size: rem(64px);
    line-height: rem(62px);
  }
}

h2,
.h2 {
  font-family: 'PP Woodland Regular';
  font-size: rem(32px);
  line-height: rem(36px);
  color: $color-text-dark;
  @include breakpoint('md-and-up') {
    font-size: rem(48px);
    line-height: rem(48px);
  }
}

h3,
.h3 {
  font-family: 'PP Woodland Regular';
  font-size: rem(32px);
  line-height: rem(36px);
  color: $color-text-dark;
}

h4,
.h4 {
  font-family: 'PP Woodland Regular';
  font-size: rem(24px);
  line-height: rem(27px);
  color: $color-text-dark;
}

h5,
.h5 {
  font-family: 'PP Woodland Regular';
  font-size: rem(16px);
  line-height: rem(20px);
  color: $color-text-dark;
}

h6,
.h6 {
  font-size: rem(16px);
  line-height: rem(24px);
  color: $color-text-dark;
}

p a {
  color: $color-blue-1;
}

.text-small {
  font-size: rem(12px);
  line-height: rem(14px);
}

.text-strong {
  font-weight: bold;
  color: $color-text-dark;
}

.-emo-gradient {
  @include add-text-gradient();
  padding: rem(0.1em 0);
}

.-sci-gradient {
  @include add-text-gradient($text-sci-gradient);
  padding: rem(0.1em 0);
}

.error--text {
  color: #f00 !important;
}

.error-dialog {
  position: relative;
  background: $color-bg;
  padding: rem(48px);
  border-radius: rem(40px) !important;
}

.error-dialog-close {
  margin-left: auto;
  position: relative;
  display: block;
  width: rem(16px);
  height: rem(16px);

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: $color-text-dark;
    position: absolute;
    top: 50%;
    left: 0;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {

    transform: rotate(-45deg);
  }
}

.error-dialog-button {
  background: $text-sci-gradient;
  color: #fff !important;

  svg {
    width: 1em;
    height: 1em;
  }
}

.v-application {
  p:last-child {
    margin-bottom: 0;
  }

  .button {
    background: $text-sci-gradient;
    color: #fff;
    text-decoration: none;
    padding: rem(16px 24px);
    border-radius: rem(30px);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: rem(320px);

    text-transform: initial;
    letter-spacing: 0;
    outline: none;
    background-size: 130% 130%;
    background-position: 0% 0%;
    transition: background-position 0.4s;

    &:hover {
      background-position: 60% 60%;

      svg {
        animation: arrowHover 0.5s;
      }
    }

    svg {
      width: 1em;
      height: 1em;
    }

    &:disabled {
      background: transparent;
      color: $color-detail;
      box-shadow: 0 0 0 1px $color-detail;
      pointer-events: none;
    }
  }
}

@keyframes arrowHover {
  0% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }

  33% {
    transform: translate3d(50%, -50%, 0);
    opacity: 0;
  }

  34% {
    transform: translate3d(-50%, 50%, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }
}

@keyframes arrowHoverReverse {
  0% {
    transform: translate(0%, 0%) scale(-1);
    opacity: 1;
  }

  33% {
    transform: translate(-50%, 50%) scale(-1);
    opacity: 0;
  }

  34% {
    transform: translate(50%, -50%) scale(-1);
    opacity: 0;
  }

  100% {
    transform: translate(0%, 0%) scale(-1);
    opacity: 1;
  }
}
</style>
