import packageConfig from '../package.json'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Platform from '@monogrid/js-utils/lib/Platform'

import router from './plugins/router'
import './plugins/analytics'
import { CMSAuth, CMSAuthPlugin } from './plugins/CMSAuth'
import VueMeta from 'vue-meta'
import VueEvents from 'vue-event-handler'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import DatGui from '@cyrilf/vue-dat-gui'
import Preloader from './preloader'
import Viewport from './directives/Viewport'
import WindowMouseMove from './directives/WindowMouseMove'
import onlineApolloProvider, { contentClient } from './services/CMS'
// import CMSOffline from './services/CMSOffline'
// import cmsOfflineCatalog from './services/CMSOfflineCatalog.json'
// import cmsOfflineSchema from './services/CMSOfflineSchema.graphql'

// usage: https://vuetifyjs.com/
import vuetify from './plugins/vuetify'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import GetUser from '@/graphql/GetUser.gql'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

// usage: https://github.com/declandewet/vue-meta
Vue.use(VueMeta)
// usage: https://github.com/sandeepk01/vue-event-handler
Vue.use(VueEvents)
// usage: https://github.com/imcvampire/vue-axios
Vue.use(VueAxios, axios)

Vue.use(CMSAuthPlugin, { userQuery: GetUser, contentClient })

// Vue.use(DatGui)

Vue.directive('viewport', Viewport)
Vue.directive('cursormove', WindowMouseMove)
//
//
// Initialization of the preloader app (placed on the HTML)
//
//
const preloaderInstance = new Vue(Preloader)

// management of languages
router.beforeEach((to, from, next) => {
  // set current language on change route
  // preloaderInstance.visible = true
  if (packageConfig.multilanguage) {
    if (to.params.language) {
      Vue.config.language = to.params.language || 'en'
    }
  } else {
    Vue.config.language = 'en'
  }
  next()
})
// router.afterEach((to, from) => {
//   preloaderInstance.visible = false
// })

//
//
// Initialization of the app (placed on the HTML)
//
//

CMSAuth.getLoggedUser().then(user => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    vuetify,
    /**
     * this is an example of using the online server in all the stages
     * and using offline data in a production stage (for example if deployed on client servers)
     * rename the production stage to "production" if you want to use this configuration
     */
    // apolloProvider: process.env.SERVICE_STAGE !== 'my-production-stage' ? onlineApolloProvider : new CMSOffline(cmsOfflineCatalog, cmsOfflineSchema).getProvider(),
    /**
     * use this if you want the online-only version of the CMS data
     */
    apolloProvider: onlineApolloProvider,
    data () {
      return {
        preloader: preloaderInstance,
        process: {
          env: process.env
        }
      }
    },
    computed: {
      platform () {
        return Platform
      },
      multilanguage () {
        return packageConfig.multilanguage
      }
    },
    // this block allows passing props to the main App
    // these props are set by html-webpack-plugin in the html
    // and the values are taken from git-revision-webpack-plugin
    render (createElement) {
      return createElement(App, {
        props: {
          preloader: preloaderInstance
        }
      })
    }
  })
  return null
}).catch(e => { console.error(e) })
