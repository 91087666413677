<template>
  <transition @enter="enterWizard" @leave="leaveWizard" :css="false">
    <div ref="modal" class="modal -fullFixed" v-if=" isActive">
      <div ref="bg1" class="bg-1 -below"></div>
      <div ref="bg2" class="bg-2 -below"></div>
      <div ref="bg3" class="bg-3 -below"></div>
      <div ref="formBgBump" class="form-bg-bump -below"></div>
      <div ref="formBg" class="form-bg -below"></div>
      <header class="-above">
        <v-container fluid class="d-flex justify-space-between align-center">
          <Logo class="Logo" @click="deactivate"/>
          <button class="close" type="button" @click="deactivate" aria-label="Close"></button>
        </v-container>
      </header>
      <Form class="-full -below text-center" ref="form" action="javascript:void(0);">
        <component
          class="step -above"
          :class="`step-${i}`"
          ref="steps"
          :data-step="i"
          v-for="(step, i) in steps"
          :key="step.id"
          :is="step.component"
          :isSubmitting="submitting"
          @next="onNext"
          @prev="onPrev"
          @submit="onSubmit"
          @close-modal="deactivate"
        />
      </Form>
    </div>
  </transition>
</template>

<script>
import { gsap } from 'gsap'
import Logo from '@/assets/img/regi-extended.svg'
import { startProjectEvent } from '@/constants'
import ActiveStateMixin from '@/mixins/ActiveStateMixin'
import Form from '@/atoms/Form'

import Step1 from './step-1'
import Step2 from './step-2'
import Step3 from './step-3'
// import Step4 from './step-4'
import Step5 from './step-5'
import Step6 from './step-6'

const stateMixin = ActiveStateMixin(startProjectEvent)
const steps = [
  { component: Step1, id: 'Step1', bg1: [-20, 20], bg2: [0, 0], bg3: [10, 20] },
  { component: Step2, id: 'Step2', bg1: [20, 20], bg2: [10, 10], bg3: [-10, 20] },
  { component: Step3, id: 'Step3', bg1: [20, -20], bg2: [-10, 0], bg3: [10, -20] },
  // { component: Step4, id: 'Step4', bg1: [-20, 20], bg2: [0, 0], bg3: [10, 20] },
  { component: Step5, id: 'Step5', bg1: [20, 20], bg2: [10, 10], bg3: [-10, 20] },
  { component: Step6, id: 'Step6', bg1: [20, -20], bg2: [-10, 0], bg3: [10, -20] }
]

export default {
  name: 'Wizard',
  mixins: [stateMixin],
  components: {
    Logo,
    Form
  },
  data () {
    return {
      steps: steps,
      currentStepIndex: 0,
      submitting: false
    }
  },
  computed: {
    currentStepComponent () {
      return steps[this.currentStepIndex]
    }
  },
  watch: {
    $route () {
      this.deactivate()
    },
    isActive (value) {
      if (!this.$gtag) return
      if (value) {
        this.$gtag.pageview({
          page_path: '/start-a-project',
          page_title: 'Start a project'
        })
      } else {
        this.$gtag.pageview({
          page_path: this.$route.path,
          page_title: this.$route.name
        })
      }
    },
    currentStepIndex (current, prev) {
      if (!this.isActive) return
      if (steps.length - this.currentStepIndex <= 0) {
        return
      }
      this.tl = gsap.timeline()
      this.tl.add(this.leave(prev))
      this.tl.add(this.enter(current))
    }
  },
  methods: {
    onNext () {
      if (steps.length - this.currentStepIndex > 0) {
        this.currentStepIndex++
      }
    },
    onPrev () {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--
      }
    },
    onSubmit () {
      if (this.submitting) return
      this.submitting = true
      if (this.$refs.form.validate()) {
        this.axios.post('/api/send-email', this.$refs.form.getValues())
          .then((response) => {
            this.submitting = false
            this.onNext()
            return true
          })
          .catch(function (error) {
            console.log(error)
            this.submitting = false
          })
      } else {
        this.submitting = false
        const errorField = this.$refs.form.getErrorField()
        if (errorField) this.goToErrorField(errorField.$el)
      }
    },
    goToErrorField ($el) {
      const step = $el.closest('.step')
      if (step) {
        this.currentStepIndex = parseInt(step.getAttribute('data-step'))
      }
    },
    enter (stepIndex) {
      const tl = gsap.timeline()
      const bb = this.$refs.steps[stepIndex].$el.getBoundingClientRect()
      const bgSize = Math.max(bb.width, bb.height) * 1.3
      this.$refs.steps[stepIndex].enter && this.$refs.steps[stepIndex].enter(bgSize)
      for (let i = 1; i <= 3; i++) {
        tl.to(this.$refs[`bg${i}`], {
          duration: 0.5,
          xPercent: steps[stepIndex][`bg${i}`][0],
          yPercent: steps[stepIndex][`bg${i}`][1]
        }, 0)
      }
      tl.to(this.$refs.formBgBump, {
        duration: 0.5,
        scale: 1.2,
        width: bgSize,
        height: bgSize,
        autoAlpha: 1,
        ease: 'power2.inOut'
      }, 0)

      tl.to(this.$refs.formBgBump, {
        duration: 0.6,
        scale: 1,
        autoAlpha: 0,
        xPercent: -50,
        yPercent: -50,
        x: 0,
        y: 0,
        ease: 'power2.out'
      }, 0.5)

      // tl.fromTo(this.$refs.formBgBump, { opacity: 0 }, { duration: 0.5, opacity: 1 }, 0)
      // tl.to(this.$refs.formBgBump, { duration: 0.5, opacity: 0 }, 0.5)

      tl.to(this.$refs.formBg, {
        duration: 0.5,
        opacity: 1,
        width: bgSize,
        height: bgSize
      }, 0)

      tl.to(this.$refs.formBg, {
        duration: 0.5,
        scale: 1.2,
        xPercent: -50,
        yPercent: -50,
        x: 0,
        y: 0,
        ease: 'power2.inOut'
      }, 0.2)
      tl.to(this.$refs.formBg, {
        duration: 0.6,
        scale: 1,
        xPercent: -50,
        yPercent: -50,
        x: 0,
        y: 0,
        ease: 'power2.out'
      }, 0.7)

      tl.set(this.$refs.steps[stepIndex].$el, {
        autoAlpha: 1
      })

      tl.fromTo(`.step-${stepIndex} .char`, {
        yPercent: 200
      }, {
        duration: 0.2,
        stagger: 0.01,
        yPercent: 0
      })

      tl.fromTo(`.step-${stepIndex} .stagger`, {
        autoAlpha: 0
      }, {
        duration: 0.3,
        autoAlpha: 1,
        stagger: 0.1
      }, '-=0.2')
      tl.set(this.$refs.steps[stepIndex].$el, { pointerEvents: 'auto' })
      return tl
    },
    leave (stepIndex) {
      this.$refs.steps[stepIndex].enter && this.$refs.steps[stepIndex].exit()

      const tl = gsap.timeline()

      tl.set(this.$refs.steps[stepIndex].$el, { pointerEvents: 'none' })
      tl.to(`.step-${stepIndex} .char`, {
        yPercent: -100,
        duration: 0.2,
        stagger: 0.01
      })

      tl.to(`.step-${stepIndex} .stagger`, {
        duration: 0.3,
        autoAlpha: 0
      }, 0)

      tl.set(this.$refs.steps[stepIndex].$el, {
        autoAlpha: 0,
        pointerEvents: 'none'
      })
    },
    enterWizard (el, done) {
      gsap.fromTo(el, { autoAlpha: 0 }, {
        duration: 0.5,
        autoAlpha: 1,
        onComplete: () => {
          this.enter(this.currentStepIndex)
          done()
        }
      })
    },
    leaveWizard (el, done) {
      gsap.to(el, {
        autoAlpha: 0,
        onComplete: () => {
          this.currentStepIndex = 0
          done()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  z-index: $z-modal;
  background: $color-bg;
}

.bg-1,
.bg-2,
.bg-3 {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: rem(600px);
  height: rem(600px);
  @include breakpoint('md-and-up') {
    width: 100%;
    height: 100%;
  }
}

.bg-1 {
  transform: translate(50%, -50%);
  background: $bg-emo-gradient-3;
}

.bg-2 {
  background: $bg-emo-gradient-2;
}

.bg-3 {
  transform: translate(0, 50%);
  background: $bg-emo-gradient-1;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-above;
  pointer-events: none;
  padding: rem(15px 0);

  > * {
    pointer-events: auto;
  }
}

.Logo {
  width: rem(75px);
  height: rem(30px);
  @include breakpoint('sm-and-up') {
    width: rem(100px);
  }
}

.close {
  position: relative;
  display: block;
  width: rem(20px);
  height: rem(20px);
  outline: none;

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: $color-text-dark;
    position: absolute;
    top: 50%;
    left: 0;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {

    transform: rotate(-45deg);
  }
}

// .form {

// }

.form-bg,
.form-bg-bump {
  opacity: 0;
  background: $color-bg;
  border-radius: 50%;
  height: 0;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.form-bg-bump {
  background: transparent;
  border: rem(5px) solid $color-bg;
}

.steps {
  max-width: rem(500px);
  text-align: center;
}

.step {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  opacity: 0;
  pointer-events: none;
  padding: rem(48px 0)
  // visibility: hidden;
}

::v-deep {
  .stagger {
    will-change: transform, opacity;
  }

  .step-title {
    line-height: 1em;
    color: $color-blue-2;

    .word,
    .char {
      display: inline-block;
      overflow: hidden;
      vertical-align: top;
    }
  }

  .v-text-field {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
