import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import find from 'lodash/find'
/**
 *
 */
class CMSAuthManager {
  /**
   *
   */
  constructor () {
    this.errors = [
      {
        name: 'password_uppercase',
        test: (message) => /Password must have uppercase characters/.test(message),
        message: () => ('Password must have at least one Upper Case character')
      },
      {
        name: 'password_lowercase',
        test: (message) => /Password must have lowercase characters/.test(message),
        message: () => ('Password must have at least one Lower Case character')
      },
      {
        name: 'password_length',
        test: (message) => /Password not long enough/.test(message),
        message: () => ('Password must be at least 8 characters')
      },
      {
        name: 'password_number',
        test: (message) => /Password must have numeric characters/.test(message),
        message: () => ('Password must have at least one number')
      },
      {
        name: 'invalid_email',
        test: (message) => /(Invalid email address format|Value at 'username' failed to satisfy constraint)/.test(message),
        message: () => ('Email must be valid')
      },
      {
        name: 'login_failed',
        test: (message) => /UserNotFoundException/.test(message), // happens on code verification and login
        message: () => ('Email not found')
      },
      {
        name: 'code_mismatch',
        test: (message) => /CodeMismatchException/.test(message), // happens on code verification and login
        message: () => ('The code you entered is not valid.')
      },
      {
        name: 'login_failed_1',
        test: (message) => /NotAuthorizedException/.test(message),
        message: () => ('Login information incorrect')
      },
      {
        name: 'signup_existing_user',
        test: (message) => /(UsernameExistsException|PreSignUp\+failed\+with\+error\+E-mail\+Already\+exists|PreSignUp failed with error E-mail Already exists)/.test(message),
        message: () => ('User already registered, try signin in with google or with email and password')
      },
      {
        name: 'forgot_password_limit',
        test: (message) => /Attempt limit exceeded, please try after some time./.test(message),
        message: () => ('Password reset attempt limit exceeded, please try after some time.')
      },
      {
        name: 'change_password_session_expired', // when user tries to change passwords more than once
        test: (message) => /Invalid session for the user, session can only be used once./.test(message),
        message: () => 'This invitation link has expired.'
      },
      {
        name: 'reset_password_code_expired', // when user tries to change passwords more than once
        test: (message) => /(ExpiredCodeException|Invalid code provided, please request a code again.)/.test(message),
        message: () => 'This Password reset code has expired, please request a new password reset link'
      }
    ]

    this.emailRules = [
      v => !!v || ('Email is required'),
      v => /.+@.+\..+/.test(v) || this.getErrorByName('invalid_email').message()
    ]

    this.passwordRules = [
      v => !!v || ('Password is required'),
      v => (v && v.length >= 8) || this.getErrorByName('password_length').message(),
      v => (v && !!v.match(/(?=.*[a-z])/)) || this.getErrorByName('password_lowercase').message(),
      v => (v && !!v.match(/(?=.*[A-Z])/)) || this.getErrorByName('password_uppercase').message(),
      v => (v && !!v.match(/(?=.*\d)/)) || this.getErrorByName('password_number').message()
    ]

    this.codeRules = [
      v => !!v || ('Please insert your Verification code'),
      v => (v && v.length === 6) || ('The Verification code must be 6 numbers')
    ]

    this.working = false
    this.newPasswordRequired = false
    this.cognitoUser = null
    this.user = null
    this.authError = null

    Hub.listen('auth', async ({ payload: { event, data } }) => {
      console.log('Hub received event', event, data)
      let error
      switch (event) {
        case 'signUp':
          // data is User
          // app.loggedUser = data.user
          break
        case 'completeNewPassword_failure':
        case 'forgotPassword_failure':
        case 'forgotPasswordSubmit_failure':
        case 'signUp_failure':
          // data is error message (if string)
          // data is {code, message, name} (if object)
          error = this.getErrorForServerMessage(data)
          if (error) this.authError = error.message()

          // if (data.code && (data.code === 'InvalidParameterException' || data.code === 'InvalidPasswordException')) {
          //   Vue.set(this, 'authError', data.message) // we need the message, the code is not useful
          // } else {
          //   Vue.set(this, 'authError', data.code ? data.code : data.message ? data.message.split('+').join(' ') : 'Unknown Error code')
          // }
          break
        case 'signIn_failure':
          // data is error message (if string)
          // data is {code, message, name} (if object)
          if (!Object.prototype.hasOwnProperty.call(data, 'code') || (Object.prototype.hasOwnProperty.call(data, 'code') && data.code !== 'UserNotConfirmedException')) {
            error = this.getErrorForServerMessage(data)
            if (error) this.authError = error.message()
          }
          break
        case 'signIn':
          this.cognitoUser = data
          this.working = true
          this.user = await this.fetchCmsUser()
          this.working = false
          break
        case 'signOut':
          // data is signed out User
          this.cognitoUser = null
          this.user = null
          break
      }
    })

    /**
     * this is only needed for google login
     */
    // Auth.configure({
    //   identityPoolId: process.env.IDENTITY_POOL_ID,
    //   region: process.env.SERVICE_REGION,
    //   userPoolId: process.env.USER_POOL_ID,
    //   userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
    //   oauth: {
    //     // domain: `${process.env.SERVICE_NAME}-${process.env.SERVICE_STAGE}.auth.${process.env.SERVICE_REGION}.amazoncognito.com`,
    //     // domain: `${process.env.SERVICE_NAME}-${process.env.SERVICE_STAGE}-auth.monogrid.io`,
    //     domain: `auth-${process.env.SERVICE_STAGE}.if-festival.it`,
    //     redirectSignIn: window.location.href.indexOf('localhost') !== -1 ? 'http://localhost:8080' : process.env.SERVICE_STAGE === 'develop' ? `https://${process.env.SERVICE_TARGET_DOMAIN}` : 'https://italiansfestival.it/live/',
    //     redirectSignOut: window.location.href.indexOf('localhost') !== -1 ? 'http://localhost:8080' : process.env.SERVICE_STAGE === 'develop' ? `https://${process.env.SERVICE_TARGET_DOMAIN}` : 'https://italiansfestival.it/live/',
    //     responseType: 'code',
    //     scope: ['profile', 'email', 'openid'],
    //     options: {
    //       urlOpener: async (url, redirectSignIn) => {
    //         // debugger
    //         const windowProxy = window.open(url, '_top')
    //         if (windowProxy) {
    //           return Promise.resolve(windowProxy)
    //         } else {
    //           return Promise.reject(new Error())
    //         }
    //       }
    //     }
    //   }
    // })
  }

  getErrorByName (name) {
    return find(this.errors, { name })
  }

  getErrorForServerMessage (serverResponse) {
    let messageToTest = JSON.stringify(serverResponse)
    if (messageToTest === '{}') {
      messageToTest = serverResponse.code + serverResponse.message
    }
    const found = find(this.errors, (err) => err.test(messageToTest))
    return found || (
      process.env.SERVICE_STAGE === 'develop'
        ? {
            name: 'unknown',
            message: () => ('Unknown Error: ') + messageToTest
          }
        : null
    )
  }

  async getLoggedUser () {
    this.working = true

    try {
      this.anonymousCredentials = null
      this.cognitoUser = await Auth.currentAuthenticatedUser()
      this.user = await this.fetchCmsUser()
      this.working = false
      return this.user
    } catch (e) {
      this.anonymousCredentials = await Auth.currentCredentials()
      this.cognitoUser = null
      this.user = null
      this.working = false
      return null
    }
  }

  /**
   *
   * @param {*} email
   * @param {*} password
   */
  async signIn (email, password) {
    this.working = true

    try {
      this.cognitoUser = await Auth.signIn(email, password)

      if (this.cognitoUser.challengeName) {
        switch (this.cognitoUser.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            this.newPasswordRequired = true
            break
          case 'SMS_MFA':
          case 'SOFTWARE_TOKEN_MFA':
          case 'MFA_SETUP':
            // just in case, possible challenges
            break
        }
      }

      this.working = false
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
   *
   * @param {*} user
   * @param {*} newPassword
   */
  async completeNewPassword (user, newPassword) {
    // const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
    this.working = true

    try {
      this.cognitoUser = await Auth.completeNewPassword(user, newPassword)
      this.newPasswordRequired = false
      this.working = false
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
   *
   * @param {*} user
   * @param {*} code
   * @param {*} mfaType
   */
  async confirmSignin (user, code, mfaType = 'SMS_MFA') {
    this.working = true

    try {
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      await Auth.confirmSignIn(
        user, // Return object from Auth.signIn()
        code, // Confirmation code
        mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      )
      // const loggedUser = await Auth.confirmSignIn(
      //   user, // Return object from Auth.signIn()
      //   code, // Confirmation code
      //   mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      // )
      // Vue.set(this, 'cognitoUser', loggedUser)
      this.working = false
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
   *
   * @param {*} email
   * @param {*} password
   */
  async signup (email, password, clientMetadata) {
    this.working = true
    try {
      const data = await Auth.signUp({
        username: email,
        email,
        password,
        attributes: {
          email
        },
        validationData: [], // optional
        clientMetadata
      })
      this.working = false
      return data
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
   *
   * @param {*} email
   * @param {*} code
   */
  async confirmSignup (email, code) {
    this.working = true

    try {
      // After retrieving the confirmation code from the user
      const data = await Auth.confirmSignUp(email, code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true
      })
      this.working = false

      return data
    } catch (e) {
      this.working = false
      throw e
    }
  }

  async federatedSignIn () {
    Auth.federatedSignIn({ provider: 'Google' })
  }

  /**
   *
   * @param {*} email
   */
  async resendSignUp (email) {
    this.working = true

    try {
      const data = await Auth.resendSignUp(email)
      this.working = false

      return data
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
  *
  */
  // async verifyEmail (email) {
  //   if (this.loggedUser) {
  //     this.working = true

  //     try {
  //       const data = await Auth.verifyCurrentUserAttribute('email')
  //       this.working = false

  //       return data
  //     } catch (e) {
  //       this.working = false
  //       throw e
  //     }
  //   }
  // }

  /**
   *
   */
  async signOut () {
    this.working = true

    try {
      await Auth.signOut()
      // Vue.set(this, 'cognitoUser', null)
      this.working = false
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
   *
   * @param {*} user
   * @param {*} oldPassword
   * @param {*} newPassword
   */
  async changePassword (user, oldPassword, newPassword) {
    this.working = true
    try {
      const data = await Auth.changePassword(user, oldPassword, newPassword)
      this.working = false

      return data
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
   *
   * @param {*} email
   */
  async sendForgotPasswordRequest (email) {
    this.working = true
    try {
      const data = await Auth.forgotPassword(email)
      this.working = false

      return data
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
   *
   * @param {*} email
   * @param {*} code
   * @param {*} password
   */
  async answerForgotPasswordCode (email, code, password) {
    this.working = true
    try {
      const data = await Auth.forgotPasswordSubmit(email, code, password)
      this.working = false

      return data
    } catch (e) {
      this.working = false
      throw e
    }
  }

  /**
   *
   * @param {*} email
   * @param {*} code
   * @param {*} newPassword
   */
  async confirmForgotPassword (email, code, newPassword) {
    this.working = true
    try {
      const data = Auth.forgotPasswordSubmit(email, code, newPassword)
      this.working = false

      return data
    } catch (e) {
      this.working = false
      throw e
    }
  }

  async fetchCmsUser () {
    // console.log(this.contentClient, this.userQuery)
    const result = await this.contentClient.query({
      query: this.userQuery,
      fetchPolicy: 'network-only',
      variables: {
        id: this.cognitoUser.username,
        status: 'DRAFT'
      }
    })

    // console.log('ok')
    return result.data.User
  }
}
const CMSAuth = new CMSAuthManager()

class CMSAuthPlugin {
  static install (Vue, { userQuery, contentClient }) {
    CMSAuth.contentClient = contentClient
    CMSAuth.userQuery = userQuery
    Vue.mixin({
      beforeCreate () {
        this.$auth = Vue.observable(CMSAuth)
      }
    })
  }
}

export { CMSAuthPlugin, CMSAuth }
