<template>
  <fieldset
    class="pb-2"
    :class="{
      ['input-error']: !!error,
    }"
  >
    <legend v-if="label">{{label}}</legend>

    <div
      ref="inputContainer"
      class="choice-button"
      :class="choiceClasses"
      v-for="(choice, i) in choices"
      :key="i">
      <input
        v-bind="$attrs"
        v-model="currentValue"
        :id="`${name}-${i}`"
        :name="`${name}${type == 'checkbox' ? '[]' : ''}`"
        :type="type"
        :value="choice"
        :checked="value === choice"
      >
      <label class="text-small" :for="`${name}-${i}`">{{choice}}</label>
    </div>
    <p class="error text-small py-1">{{error || '&nbsp;'}}</p>
  </fieldset>
</template>

<script>
export default {
  name: 'ChoiceGroup',
  inject: {
    registerField: { default: () => {} },
    unRegisterField: { default: () => {} }
  },
  props: {
    label: { type: String },
    choiceClasses: { type: String, default: '' },
    value: { type: [String, Number] },
    required: { type: Boolean },
    choices: { type: Array, required: true },
    type: { type: String, default: 'radio' },
    name: { type: String, required: true },
    placeholder: { type: String, default: '' }
  },
  data () {
    return {
      isValid: !this.required,
      currentValue: this.value,
      error: false
    }
  },
  created () {
    this.registerField(this)
  },
  beforeDestroy () {
    this.unRegisterField(this)
  },
  watch: {
    value (newValue) {
      if (newValue !== this.currentValue) {
        this.currentValue = newValue
      }
    },
    currentValue () {
      this.onChange()
    }
  },
  methods: {
    reset () {
      this.isValid = !(this.rules.length > 0 || this.required)
      this.error = false
      this.focused = false
      this.currentValue = null
    },
    onChange (e) {
      this.validate()
      this.$emit('input', this.currentValue)
    },
    validate () {
      let error = false
      let isValid = true
      if (this.required) {
        const result = !!this.currentValue || ('This field is required')
        if (result !== true) {
          error = result
          isValid = false
        }
      }
      this.error = error
      this.isValid = isValid
      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

.choice-button {
  display: inline-block;
  margin: rem(4px);
}

input {
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
}

input + label {
  min-width: rem(132px);
  box-shadow: inset 0 0 0 1px $color-detail;
  border-radius: rem(15px);
  display: inline-block;
  padding: rem(8px 32px);
  font-weight: bold;
  transition: color 0.2s, background-color 0.2s;
  cursor: pointer;

  color: $color-text-dark;

  &:hover {
    color: $color-blue-1;
  }

  .input-error & {
    border-color: #f00;
    color: #f00;
  }
}

input:checked + label {
  background: $text-sci-gradient;
  color: $color-bg;
  box-shadow: inset 0 0 0 1px transparent;
}
</style>
