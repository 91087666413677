<template>
  <aside class="menu -fullFixed" :class="{active: isActive}">
    <div class="bg-1 -below"></div>
    <div class="bg-2 -below"></div>
    <v-container class="menu-container -above" v-if="Menu">
      <v-row class="menu-links">
        <v-col cols="12" md="4" v-for="(menuGroup, index) in Menu.menu" :key="index">
          <small class="stagger text-small text-uppercase mb-4">{{ menuGroup.name }}</small>
          <ul>
            <li v-for="menuItem in menuGroup.items" :key="menuItem.id" class="stagger">
              <MenuItem v-bind="menuItem" class="menu-item mb-2" />
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="4" v-if="$auth.user">
          <small class="stagger text-small text-uppercase mb-4">Account</small>
          <Cta
            reverse
            v-if="$auth.user"
            @click="$auth.signOut()"
            :disabled="$auth.working"
            class="stagger"
            action="button"
            label="Logout"
          />
        </v-col>
      </v-row>
      <v-row class="menu-footer">
        <v-col cols="12" md="3"
          v-for="headquarter in Footer.footerHeadquarters"
          :key="headquarter.id"
        >
          <HeadquarterResume v-bind="headquarter" class="hq-resume stagger-footer" />
        </v-col>
        <v-col cols="12" md="3" class="stagger-footer">
          <p class="text-small ">{{Footer.footerCopyrightText}}</p>
        </v-col>
      </v-row>
    </v-container>
  </aside>
</template>

<script>
import { menuEvent } from '@/constants'
import GetMenu from '@/graphql/GetMenu.gql'
import GetFooter from '@/graphql/GetFooter.gql'
import ActiveStateMixin from '@/mixins/ActiveStateMixin'
import MenuItem from '@/atoms/MenuItem'
import Cta from '@/molecules/Cta'
import HeadquarterResume from '@/atoms/HeadquarterResume'
import { gsap } from 'gsap'
const stateMixin = ActiveStateMixin(menuEvent)

export default {
  name: 'Menu',
  mixins: [stateMixin],
  components: {
    MenuItem,
    Cta,
    HeadquarterResume
  },
  apollo: {
    Menu: {
      query: GetMenu,
      variables () {
        return {
          slug: 'globals'
        }
      }
    },
    Footer: {
      query: GetFooter,
      variables () {
        return {
          slug: 'globals'
        }
      }
    }
  },
  watch: {
    $route () {
      this.deactivate()
    },
    isActive (value) {
      value ? this.enter() : this.exit()
    }
  },
  methods: {
    enter () {
      this.tl && this.tl.kill()
      this.tl = gsap.timeline({ defaults: { ease: 'circ.out' } })
      this.tl.to(this.$el, { duration: 0.5, autoAlpha: 1 })
      this.tl.to('.menu .stagger', { duration: 0.4, autoAlpha: 1, stagger: 0.1, y: 0 }, 0.5)
      this.tl.to('.menu .stagger-footer', { duration: 0.4, autoAlpha: 1, stagger: 0.1, y: 0 }, 0.5)
    },
    exit () {
      this.tl && this.tl.kill()
      this.tl = gsap.timeline({
        defaults: { ease: 'circ.in' },
        onComplete: () => {
          gsap.set('.menu .stagger', { y: 50 }, 0)
          gsap.set('.menu .stagger-footer', { y: 50 }, 0)
        }
      })
      this.tl.to('.menu .stagger', { duration: 0.3, autoAlpha: 0 }, 0)
      this.tl.to('.menu .stagger-footer', { duration: 0.3, autoAlpha: 0 }, 0)
      this.tl.to(this.$el, { duration: 0.5, autoAlpha: 0 }, 0.2)
    }
  }
}
</script>

<style lang="scss" scoped>
aside {
  z-index: $z-menu;
  pointer-events: none;
  overflow: hidden;
  padding: rem(120px 0 24px);
  background: $color-bg;
  visibility: hidden;
  opacity: 0;

  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  &.active {
    pointer-events: auto;
  }
}

.stagger,
.stagger-footer {
  display: block;
  opacity: 0;
  transform: translate(0, 50px);
}

.bg-1,
.bg-2 {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
  padding-bottom: 100%;
}

.bg-1 {
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
  background: $bg-sci-gradient-3;
}

.bg-2 {
  left: 0;
  top: 0;
  background: $bg-sci-gradient-1;
  transform: translate(10%, 0%);
}

.menu-container {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  list-style: none;
  padding: rem(32px 0) !important;
}

.menu-links {
  flex: 1 0 auto;

  small {
    color: transparentize($color-text, 0.5);
  }
}

.menu-footer {
  flex: 0 1 auto;
}

.menu-item {
  display: inline-block;
  text-decoration: none;
  padding: rem(8px 0);
}

.hq-resume {
  margin-bottom: rem(24px);
}
</style>
