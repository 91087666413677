<template>
  <router-link
    :to="{ path: link }"
    class="h2 menu-item"
    exact-active-class="exact-page"
    active-class="active-page"
  >
    {{label}}
  </router-link>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    title: {
      type: String
    },
    useCustomTitle: {
      type: Boolean
    },
    customPath: {
      type: String
    },
    modularPage: {
      type: Object
    }
  },
  computed: {
    link () {
      if (this.modularPage) {
        return '/' + this.modularPage._slug
      }
      return this.customPath
    },
    label () {
      if (!this.useCustomTitle && this.modularPage) {
        return this.modularPage.title
      }
      return this.title
    }
  }
}
</script>

<style lang="scss" scoped>
.exact-page {
  pointer-events: none;
}

.active-page {
  text-decoration: none !important;
  color: $color-blue-2;
}

.menu-item {
  display: inline-block;
  transition: color 0.2s, transform 0.2s;

  @include breakpoint('md-and-up') {
    &:hover:not(.current-page) {
      transform: translateX(10px);
    }

    &:hover {
      color: $color-blue-2;
    }
  }
}
</style>
