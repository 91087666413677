function ActiveStateMixin (triggerName = false) {
  return {
    data () {
      return {
        isActive: false
      }
    },
    mounted () {
      if (triggerName) {
        this.$events.on(`${triggerName}:activate`, this.activate)
        this.$events.on(`${triggerName}:deactivate`, this.deactivate)
      }
    },
    beforeDestroy () {
      if (triggerName) {
        this.$events.off(`${triggerName}:activate`, this.activate)
        this.$events.off(`${triggerName}:deactivate`, this.deactivate)
      }
    },
    watch: {
      isActive (value) {
        if (triggerName) {
          this.$events.emit(`${triggerName}:change`, value)
        }
      }
    },
    methods: {
      activate () { this.isActive = true },
      deactivate () { this.isActive = false },
      toggle () { this.isActive = !this.isActive }
    }
  }
}

export default ActiveStateMixin
