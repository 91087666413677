<template>
  <button
    type="button"
    :class="{active: menuIsOpen}"
    @click="onClick"
    aria-label="Open Menu"
  >
    <span>&nbsp;</span>
    <span>&nbsp;</span>
    <span>&nbsp;</span>
  </button>
</template>

<script>
import { menuEvent } from '@/constants'
import ActiveStateListenerMixin from '@/mixins/ActiveStateListenerMixin'

const listenerMixin = ActiveStateListenerMixin(menuEvent, 'menuIsOpen')

export default {
  name: 'MenuHeaderButton',
  mixins: [listenerMixin],
  methods: {
    onClick () {
      this.$events.emit(`${menuEvent}:${this.menuIsOpen ? 'deactivate' : 'activate'}`)
    }
  }
}
</script>

<style lang="scss" scoped>
$button-w: 28px;
$button-h: 11px;
$span-height: 1px;

button {
  height: rem($button-h);
  width: rem($button-w);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: 0;
  align-items: flex-end;
}

span {
  width: rem($button-w);
  height: rem($span-height);
  background-color: #000;
  transition-property: transform, opacity, background-color;
  transition-duration: 0.2s;
  display: block;

  &:nth-child(2) {
    width: rem($button-w*0.8);
  }

  &:nth-child(3) {
    width: rem($button-w*0.6);
  }

  button.active & {
    width: rem($button-w);

    &:nth-child(1) {
      transform: translateY(rem($button-h/2)) rotate(-45deg);
    }

    &:nth-child(2) {
      transform: scale(0);
      opacity: 0;
    }

    &:nth-child(3) {

      transform: translateY(rem(($button-h - $span-height)/-2)) rotate(45deg);
    }
  }
}

</style>
