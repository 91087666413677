<template>
  <div
    class="input-container pb-2"
    :class="{
      ['input-error']: !!error,
      ['input-dirty']: currentValue && currentValue.length > 0,
      ['input-focus']: focused
    }">
    <label v-if="label" class="text-small" :for="name">{{label}}</label>
    <textarea
      :id="name"
      :name="name"
      :required="required"
      :placeholder="placeholder"
      :value="currentValue"
      v-bind="$attrs"
      @blur="onBlur"
      @focus="onFocus"
      @input="onChange"
    ></textarea>
    <p class="error text-small py-1">{{error || '&nbsp;'}}</p>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  inject: {
    registerField: { default: () => {} },
    unRegisterField: { default: () => {} }
  },
  props: {
    name: { type: String, required: true },
    label: { type: String },
    value: { type: String },
    placeholder: { type: String, default: '' },
    rules: { type: Array, default: () => [] },
    required: { type: Boolean }
  },
  data () {
    return {
      isValid: !(this.rules.length > 0 || this.required),
      error: false,
      focused: false,
      currentValue: this.value
    }
  },
  created () {
    this.registerField(this)
  },
  beforeDestroy () {
    this.unRegisterField(this)
  },
  methods: {
    reset () {
      this.isValid = !(this.rules.length > 0 || this.required)
      this.error = false
      this.focused = false
      this.currentValue = null
    },
    onFocus () {
      this.focused = true
    },
    onBlur (e) {
      this.focused = false
      this.onChange(e)
    },
    onChange (e) {
      this.currentValue = e.target.value
      this.$emit('input', this.currentValue)
      this.validate()
    },
    validate () {
      let error = false

      const isValid = this.rules.every(rule => {
        const result = rule(this.currentValue)
        if (result !== true) {
          error = result
          return false
        }
        return result
      })
      this.error = error
      this.isValid = isValid
      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
  padding-top: rem(6px);
  color: $color-detail;

  width: 100%;
  max-width: rem(320px);

  &.input-dirty {
    color: $color-text;
  }

  &.input-error {
    color: #f00;
  }
}

label {
  position: absolute;
  top: 0;
  left: rem(28px);
  background: $color-bg;
  z-index: 2;
  display: inline-block;
  padding: rem(0 4px);
  color: $color-text;
}

textarea {
  border: 1px solid currentColor;
  color: currentColor;
  border-radius: rem(30px);
  outline: none;
  display: block;
  padding: rem(16px 32px);
  width: 100%;
  transition: border-color 0.2s;

  resize: none;

  &:focus {
    color: $color-text;
  }

  .input-error & {
    color: #f00;
  }
}

.error {
  padding: rem(0 28px);
}
</style>
