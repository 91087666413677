<template>
  <form v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'Form',
  provide () {
    return {
      registerField: this.registerField,
      unRegisterField: this.unRegisterField
    }
  },
  created () {
    this.fields = []
  },
  methods: {
    validate () {
      let isValid = true
      this.fields.forEach(field => {
        const validation = field.validate()
        if (!validation) isValid = validation
      })
      return isValid
    },
    reset () {
      this.fields.forEach(field => field.reset())
    },
    getValues () {
      return this.fields.reduce((acc, field) => {
        acc[field.name] = field.currentValue
        return acc
      }, {})
    },
    getErrorField () {
      return this.fields.find(field => !field.validate())
    },
    registerField (component) {
      this.fields.push(component)
    },
    unRegisterField (component) {
      const found = this.fields.find(i => i._uid === component._uid)

      if (!found) return
      this.fields = this.fields.filter(i => i._uid !== found._uid)
    }
  }
}
</script>
