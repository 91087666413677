<script>
import Platform from '@monogrid/js-utils/lib/Platform'
import { gsap } from 'gsap'
const tips = [
  'Mixing the pigments'
]
export default {
  // avoid touching the actual element on the HTML
  // if this is a prerenderer
  el: !Platform.prerenderer ? '#app-loading' : null,
  created: function () {
    // do preloading stuff here
  },
  mounted () {
    this.createTl()
    this.enter()
    window.enter = this.enter
    window.exit = this.exit
  },
  data: function () {
    return {
      visible: true
    }
  },
  watch: {
    visible (isVisible) {
      if (isVisible && this.$refs.tip) {
        this.$refs.tip.innerText = tips[~~(Math.random() * tips.length)]
      }
      if (isVisible) {
        !this.exitTl.isActive() && this.enter()
      } else {
        // !this.enterTl.isActive() && this.exit()
        this.exit()
      }
    }
  },
  methods: {
    createTl () {
      // enter animation
      this.enterTl = gsap.timeline({
        paused: true
      })
      this.enterTl.set(this.$el, { immediateRender: false, autoAlpha: 1 })
      this.enterTl.to(this.$refs.panel, { ease: 'circ.out', immediateRender: false, duration: 0.7, yPercent: 0 })
      this.enterTl.to(this.$refs.bgs, { immediateRender: false, duration: 0.5, autoAlpha: 1 }, 0.2)
      this.enterTl.to(this.$refs.content, { immediateRender: false, duration: 0.3, autoAlpha: 1 }, 0.2)

      this.enterSubTl = gsap.timeline({
        repeat: -1,
        yoyo: true
        // defaults: { ease: 'power4.inOut' }

      })
      this.enterSubTl.fromTo(this.$refs.bg1, { yPercent: 0, rotate: 0 }, { immediateRender: false, duration: 5, yPercent: -10, rotate: 360 }, 0)
      this.enterSubTl.fromTo(this.$refs.bg2, { yPercent: 0, rotate: 30 }, { immediateRender: false, duration: 5, yPercent: 5, rotate: 390 }, 0)
      this.enterSubTl.fromTo(this.$refs.bg3, { yPercent: 0, rotate: 60 }, { immediateRender: false, duration: 5, yPercent: 20, rotate: 410 }, 0)
      this.enterSubTl.to(this.$refs.bg1, { immediateRender: false, duration: 2, scale: 1, xPercent: 25 }, 0)
      this.enterSubTl.to(this.$refs.bg2, { immediateRender: false, duration: 2, scale: 1, xPercent: -25 }, 0)
      this.enterSubTl.to(this.$refs.bg3, { immediateRender: false, duration: 2, scale: 1, xPercent: 0 }, 0)

      this.enterSubTl.to(this.$refs.bg1, { immediateRender: false, duration: 4, scale: 1, xPercent: -15 }, 4)
      this.enterSubTl.to(this.$refs.bg2, { immediateRender: false, duration: 4, scale: 1, xPercent: -15 }, 4)
      this.enterSubTl.to(this.$refs.bg3, { immediateRender: false, duration: 4, scale: 1, xPercent: 10 }, 4)

      this.enterSubTl.to(this.$refs.bg1, { immediateRender: false, duration: 4, scale: 1, xPercent: -15 }, 8)
      this.enterSubTl.to(this.$refs.bg2, { immediateRender: false, duration: 4, scale: 1, xPercent: 15 }, 8)
      this.enterSubTl.to(this.$refs.bg3, { immediateRender: false, duration: 4, scale: 1, xPercent: -10 }, 8)

      this.enterSubTl.eventCallback('onRepeat', () => !this.visible && this.exit())
      this.enterTl.add(this.enterSubTl, 0)
      this.enterTl.eventCallback('onComplete', () => !this.visible && this.exit())

      // exit animation
      this.exitTl = gsap.timeline({
        paused: true,
        defaults: { ease: 'circ.inOut' },
        onComplete: () => {
          gsap.set(this.$el, { autoAlpha: 0 })
          gsap.set(this.$refs.bgs, { autoAlpha: 0 })
          gsap.set(this.$refs.bg1, { scale: 0.4, rotate: 0 })
          gsap.set(this.$refs.bg2, { scale: 0.4, rotate: 30 })
          gsap.set(this.$refs.bg3, { scale: 0.4, rotate: 60 })
          gsap.set(this.$refs.panel, { yPercent: 150 })
          this.visible && this.enter()
        }
      })
      this.exitTl.to(this.$refs.content, { immediateRender: false, duration: 0.4, autoAlpha: 0 })
      this.exitTl.to(this.$refs.bgs, { immediateRender: false, duration: 0.4, autoAlpha: 0 }, 0.2)
      this.exitTl.to(this.$refs.panel, { immediateRender: false, duration: 1, yPercent: -100 }, 0.1)
    },
    enter () {
      this.exitTl.pause()
      this.enterTl.invalidate()
      this.enterTl.play(0)
    },
    exit () {
      this.enterTl.pause()
      this.exitTl.invalidate()
      this.exitTl.play(0)
    }
  }
}
</script>
