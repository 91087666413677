function ActiveStateListenerMixin (triggerName, stateName = 'isActive') {
  return {
    data () {
      return {
        [stateName]: false
      }
    },
    mounted () {
      this.$events.on(`${triggerName}:change`, this[`onChange${triggerName}`])
    },
    beforeDestroy () {
      this.$events.off(`${triggerName}:change`, this[`onChange${triggerName}`])
    },
    methods: {
      [`onChange${triggerName}`] (value) {
        this[stateName] = value
      }
    }
  }
}

export default ActiveStateListenerMixin
