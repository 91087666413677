export default {
  isFn: true,
  bind (el, binding) {
    binding.__mmCb = e => {
      const target = e.touches ? e.touches[0] : e
      binding.value(target.clientX, target.clientY, e)
    }

    window.addEventListener('mousemove', binding.__mmCb)
    window.addEventListener('touchmove', binding.__mmCb)
  },
  unbind (el, binding) {
    window.removeEventListener('mousemove', binding.__mmCb)
    window.removeEventListener('touchmove', binding.__mmCb)
  }
}
