<template>
  <button v-bind="$attrs" v-on="$listeners" class="button justify-space-between">
    <slot></slot>
    <Arrow/>
  </button>
</template>

<script>
import Arrow from '@/assets/img/cta-arrow.svg'

export default {
  name: 'Button',
  components: { Arrow }
}
</script>
