import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from '../routes'

// docs: https://router.vuejs.org/en/
Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        } else {
          resolve({ x: 0, y: 0 })
        }
      }, 300)
    })
  }
})
