export default {
  props: {
    colorSchema: {
      type: String,
      default: 'scientific'
    }
  },
  computed: {
    gradientClass () {
      switch (this.colorSchema) {
        case 'emotional':
          return '-emo-gradient'
        case 'scientific':
          return '-sci-gradient'
        default:
          return ''
      }
    }
  }
}
