<template>
  <v-slide-x-transition mode="out-in">
    <div :key="title">
      <template v-if="!!routeInfo.length">
        <template v-for="route in routeInfo">
          <router-link
            :key="route.name"
            :to="{ name: route.name, params: route.params }"
            class="-sci-gradient text-decoration-none"
          >
            {{ route.label }}
          </router-link>
          <span :key="route.name + '/'" class="grey--text">/</span>
        </template>
      </template>
      <span class="grey--text">{{ title }}</span>
    </div>
  </v-slide-x-transition>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'AppBreadcrumbs',
  data () {
    return {
      title: '',
      routeInfo: []
    }
  },
  mounted () {
    this.$events.$on('global-breadcrumbs', (data) => {
      this.routeInfo = data
    })
  },
  methods: {
    onChange: debounce(function (newInfo, addedTags, removedTags) {
      this.title = this.$route.meta.hideBreadcrumb ? '' : newInfo.titleChunk
    }, 500)
  },
  watch: {
    $route: function () {
      this.routeInfo = []
      this.title = ''
    }
  },
  metaInfo () {
    return {
      changed: this.onChange
    }
  }
}
</script>

<style lang="scss" scoped>
h6 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
