<template>
  <div>
    <p class="stagger">3/3</p>
    <h3 class="step-title mb-8" v-html="title"></h3>
    <div class="stagger">
      <InputText
        required
        class="input"
        ref="email"
        label="Email"
        name="email"
        type="email"
        :rules="$auth.emailRules"
      />

    </div>
    <div class="stagger">
      <Textarea
        required
        :rules="[msg => !!msg || 'Message is required']"
        class="input"
        ref="message"
        name="message"
        placeholder="Write us a message"
      />
    </div>
    <div class="mb-6 stagger">
      <Button @click="$emit('submit')" type="submit" :disabled="!isValid || isSubmitting">
        Next
      </Button>
    </div>
    <div class="stagger">
      <Cta action="button" label="Back" @click="$emit('prev')" reverse colorSchema="scientific"/>
    </div>
  </div>
</template>

<script>
import Splitting from 'splitting'
import Cta from '@/molecules/Cta'
import InputText from '@/atoms/InputText'
import Textarea from '@/atoms/Textarea'
import Button from '@/atoms/Button'

export default {
  name: 'Step1',
  components: { Cta, Button, InputText, Textarea },
  props: {
    isSubmitting: { type: Boolean }
  },
  data () {
    return {
      isValid: false,
      title: Splitting.html({ content: 'Enter your email and a personal message', by: 'chars' })
    }
  },
  mounted () {
    this.$nextTick(() => (this.isValid = this.checkIsValid()))
    this.$watch('$refs.email.isValid', () => (this.isValid = this.checkIsValid()))
    this.$watch('$refs.message.isValid', () => (this.isValid = this.checkIsValid()))
  },
  methods: {
    checkIsValid () {
      return this.$refs.email.isValid && this.$refs.message.isValid
    }
  }
}
</script>
<style lang="scss" scoped>
.input {
  margin: 0 auto;
}
</style>
