<template>
  <header :class="{['-transparent']: $route.meta.headerTransparent}">
    <v-container fluid>
      <v-row>
        <v-col cols="3" md="1" class="logo-wrapper">
          <router-link to="/" aria-label="Home" class="logo">
            <Logo/>
          </router-link>
        </v-col>
        <v-col cols="9" md="11" class="d-flex align-center justify-end">
          <AppBreadcrumbs class="d-none d-md-block text-right pr-md-8 pr-lg-16 mr-lg-12" />
          <Cta
            class="header-cta"
            v-if="!!HeaderCta"
            :colorSchema="$route.meta.headerTransparent ? 'none': 'scientific'"
            :action="HeaderCta.headerCtaAction"
            :label="HeaderCta.headerCtaLabel"
          />
          <MenuHeaderButton class="ml-8"/>
        </v-col>
      </v-row>
    </v-container>
  </header>
</template>
<script>
import GetHeaderCta from '@/graphql/GetHeaderCta.gql'
import Logo from '@/assets/img/regi-extended.svg'
import MenuHeaderButton from '@/atoms/MenuHeaderButton'
import Cta from '@/molecules/Cta'
import AppBreadcrumbs from '@/atoms/AppBreadcrumbs'

export default {
  name: 'Header',
  apollo: {
    HeaderCta: {
      query: GetHeaderCta,
      variables () {
        return {
          slug: 'globals'
        }
      }
    }
  },
  components: {
    Logo,
    MenuHeaderButton,
    Cta,
    AppBreadcrumbs
  }
}
</script>

<style scoped lang="scss">

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: $z-header;
  pointer-events: none;
  background-color: $color-bg;
  transition: background-color 0.4s 0.4s;
  padding: rem(12px 0);

  > * {
    pointer-events: auto;
  }

  &.-transparent {
    background-color: transparent;
  }
}

.header-cta {
  flex: 0 0 auto;
}

.logo-wrapper {
  position: relative;
}

.logo {
  display: flex;
  align-content: center;
  position: absolute;
  top: 50%;
  left: rem(6px);
  transform: translateY(-50%);
  width: rem(75px);
  height: rem(30px);
  @include breakpoint('sm-and-up') {
    width: rem(100px);
  }
}

.header-right {
  display: flex;
  align-items: center;

  > :last-child {
    margin-left: rem(10px);
  }
}
</style>
