<template>
  <router-link
    class="text-strong"
    :to="to"
  >
    <slot name="pre-label"></slot>
    <span :class="gradientClass">{{label}}</span>
  </router-link>
</template>

<script>
export default {
  name: 'CtaInternalLink',
  props: {
    label: { type: String },
    to: { type: [String, Object] },
    gradientClass: { type: String }
  }
}
</script>
