<template>
  <a
    v-if="!!Email"
    class="text-strong d-flex"
    :href="mailTo"
  >
    <slot name="pre-label"></slot>
    <span :class="gradientClass">{{label}}</span>
  </a>
</template>

<script>
import GetContactEmail from '@/graphql/GetContactEmail.gql'

export default {
  name: 'CtaMailTo',
  apollo: {
    Email: {
      query: GetContactEmail,
      variables () {
        return {
          slug: 'globals'
        }
      }
    }
  },
  props: {
    label: { type: String },
    subject: { type: String },
    mail: { type: String },
    gradientClass: { type: String }
  },
  computed: {
    mailTo () {
      return `mailto:${this.mail || this.Email.contactMail}${this.subject ? `?subject=${encodeURIComponent(this.subject)}` : ''}`
    }
  }
}
</script>
