<template>
  <div>
    <p class="stagger">1/3</p>
    <h3 class="step-title mb-8" v-html="title"></h3>
    <div class="stagger">
      <InputText
        required
        class="input"
        ref="input"
        label="Name / Company"
        name="name"
        placeholder="Name / Company"
        :rules="[v => !!v || ('Name is required')]"
      />
    </div>
    <div class="mb-6 stagger">
      <Button @click="$emit('next')" type="button" :disabled="!isValid">
        Next
      </Button>
    </div>
    <div class="stagger">
      <Cta action="button" label="Back" @click="$emit('prev')" reverse colorSchema="scientific"/>
    </div>
  </div>
</template>

<script>
import Splitting from 'splitting'
import Cta from '@/molecules/Cta'
import InputText from '@/atoms/InputText'
import Button from '@/atoms/Button'

export default {
  name: 'Step1',
  components: { Cta, Button, InputText },
  data () {
    return {
      isValid: false,
      title: Splitting.html({ content: 'What’s your name and/or Company name', by: 'chars' })
    }
  },
  mounted () {
    this.$nextTick(() => (this.isValid = this.$refs.input.isValid))
    this.$watch('$refs.input.isValid', (value) => (this.isValid = value))
  }
}
</script>
<style lang="scss" scoped>
.input {
  margin: 0 auto;
}
</style>
