import { debounce } from 'lodash'

// Usage example : v-viewport.initial.debounce:250="onResize"

const getOptions = (modifiers = {}) => {
  const options = { debounce: 50 }
  Object.keys(modifiers).forEach(keyValue => {
    const [key, value = true] = keyValue.split(':')
    options[key] = typeof value === 'boolean' ? value : parseInt(value)
  })
  return options
}

export default {
  isFn: true,
  bind (el, binding) {
    const options = getOptions(binding.modifiers)
    // save debounced callback to remove it later
    binding.__vpCb = debounce(e => {
      binding.value(window.innerWidth, window.innerHeight, e)
    }, options.debounce)

    if (options.initial) {
      binding.value(window.innerWidth, window.innerHeight)
    }

    window.addEventListener('resize', binding.__vpCb)
  },
  unbind (el, binding) {
    window.removeEventListener('resize', binding.__vpCb)
  }
}
