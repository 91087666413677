<template>
  <button
    type="button"
    class="text-strong"
    v-on="$listeners"
  >
    <slot name="pre-label"></slot>
    <span :class="gradientClass">{{label}}</span>
  </button>
</template>

<script>

export default {
  name: 'CtaButton',
  props: {
    label: { type: String },
    gradientClass: { type: String }
  }
}
</script>
