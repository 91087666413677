<template>
  <CookieLaw v-if="!!Cookie">
    <div slot-scope="props" class="cookie-container">
      <MarkdownBlock
        ref="text"
        :inline="false"
        linkify
        tag="div"
        :text="Cookie.bannerCookieText"
      />
      <cta
        :label="Cookie.bannerCookieAcceptLabel"
        to=""
        action="button"
        @click.prevent="props.accept"
      />
    </div>
  </CookieLaw>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import { MarkdownBlock } from '@monogrid/vue-lib'
import BannerCookieQuery from '@/graphql/GetBannerCookie.gql'
import Cta from '@/molecules/Cta.vue'

export default {
  name: 'BannerCookie',
  components: {
    CookieLaw,
    MarkdownBlock,
    Cta
  },
  apollo: {
    Cookie: {
      query: BannerCookieQuery,
      variables () {
        return {
          id: 'b2d5ab91-af22-4481-aa4f-dff6835f11cc'
        }
      },
      async result () {
        this.$nextTick(() => {
          if (this.$refs.text) {
            this.addListener()
          }
        })
      }
    }
  },
  methods: {
    addListener () {
      const TAG = this.$refs.text.$el.children[0].firstElementChild
      TAG.addEventListener('click', e => {
        e.preventDefault()
        if (this.$route.name !== 'PrivacyPage') {
          this.$router.push({ name: 'PrivacyPage' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Cookie {
  position: fixed;
  height: 60px;
  max-width: 850px;
  padding: rem(22px);
  border-radius: rem(36px);
  background-color: #fff;
  color: $color-text-dark;
  margin: rem(22px) auto;
  z-index: $z-cookie-banner;

  @include breakpoint ('sm-and-down') {
    height: auto;
    max-width: 90%;
    padding-bottom: rem(12px);
  }

  .cookie-container {
    margin: 0 !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @include breakpoint ('sm-and-down') {
      height: auto;
      flex-direction: column;
      align-items: flex-end;
    }

    @include breakpoint ('xs-only') {
      line-height: rem(33.6px);
    }
  }

}

::v-deep a {
  color: $color-text-dark;
}
</style>
