// import _debounce from 'lodash/debounce'

/**
 *
 * Include this `mixin` when you want delta time function
 *
 */

export default {
  methods: {
    getDelta () {
      const now = Date.now()
      const delta = (now - (this.time || now)) / 1000
      this.time = now
      return delta
    }
  }
}
