<template>
  <button
    type="button"
    class="text-strong"
    :class="{active: isActive}"
    @click="onClick"
  >
    <slot name="pre-label"></slot>
    <span :class="gradientClass">{{label}}</span>
  </button>
</template>

<script>
import { startProjectEvent } from '@/constants'
import ActiveStateListenerMixin from '@/mixins/ActiveStateListenerMixin'

const listenerMixin = ActiveStateListenerMixin(startProjectEvent)

export default {
  name: 'CtaStartProject',
  props: {
    label: { type: String },
    gradientClass: { type: String }
  },
  mixins: [listenerMixin],
  methods: {
    onClick () {
      this.$events.emit(`${startProjectEvent}:${this.isActive ? 'deactivate' : 'activate'}`)
    }
  }
}
</script>
