<template>
  <a
    :href="`/media/${download.file}`"
    :download="download.title"
    class="text-strong"
  >
    <slot name="pre-label"></slot>
    <span :class="gradientClass">{{ label }}</span>
  </a>
</template>

<script>
export default {
  name: 'CtaDownload',
  props: {
    label: { type: String },
    download: { type: Object },
    gradientClass: { type: String }
  }
}
</script>
