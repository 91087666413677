import packageConfig from '../package.json'
import { CMSAuth } from '@/plugins/CMSAuth'
//
// only pages should be imported and used here
// http://bradfrost.com/blog/post/atomic-web-design/
//

const isClientAutorized = () => {
  return CMSAuth.user
}

const cataloguePermission = () => {
  return CMSAuth.user && CMSAuth.user.cataloguePermission
}

const AuthViewGuards = (to, from, next) => {
  if (isClientAutorized()) {
    next()
  } else {
    next(`/login?to=${to.fullPath}`)
  }
}

const CatalogueGuards = (to, from, next) => {
  if (cataloguePermission()) {
    next()
  } else {
    next('/')
  }
}

let routes = [
  {
    path: '/',
    name: 'Home',
    meta: { headerTransparent: true, hideBreadcrumb: true },
    component: () => import(/* webpackChunkName: "page-home" */ './pages/HomePage')
  },
  {
    path: '/we-are-regi',
    name: 'WeAreRegi',
    meta: { headerTransparent: true },
    component: () => import(/* webpackChunkName: "page-we-are-regi" */ './pages/WeAreRegiPage')
  },
  {
    path: '/login/:invitationEmail?/:invitationTemporaryPassword?',
    name: 'Login',
    props: true,
    component: () => import(/* webpackChunkName: "page-login" */ './pages/LoginPage')
  },
  {
    path: '/forgot-password/:precompiledEmail?/:precompiledCode?',
    name: 'ForgotPasswordPage',
    props: true,
    component: () => import(/* webpackChunkName: "page-forgot-password" */ './pages/ForgotPasswordPage')
  },
  {
    path: '/events-calendar',
    name: 'EventsCalendarPage',
    component: () => import(/* webpackChunkName: "page-events-calendar" */ './pages/EventsCalendarPage')
  },
  {
    path: '/work-with-us',
    name: 'CareersPage',
    component: () => import(/* webpackChunkName: "page-careers" */ './pages/CareersPage')
  },
  {
    path: '/catalogue',
    name: 'Catalogue',
    component: () => import(/* webpackChunkName: "page-catalogue" */ './pages/CataloguePage'),
    meta: { restricted: true },
    beforeEnter: CatalogueGuards
  },
  {
    path: '/webinars',
    name: 'Webinar',
    component: () => import(/* webpackChunkName: "page-webinar" */ './pages/WebinarPage'),
    meta: { restricted: true },
    beforeEnter: AuthViewGuards
  },
  {
    path: '/products-showcase',
    name: 'ProductsShowcase',
    component: () => import(/* webpackChunkName: "page-products-showcase" */ './pages/ProductsShowcasePage'),
    meta: { restricted: true },
    beforeEnter: AuthViewGuards
  },
  {
    path: '/products-showcase/collection/:slug',
    name: 'CollectionPage',
    props: true,
    component: () => import(/* webpackChunkName: "page-collection" */ './pages/CollectionPage'),
    meta: { restricted: true },
    beforeEnter: AuthViewGuards
  },
  {
    path: '/products-showcase/product/:slug',
    name: 'ProductPage',
    props: true,
    component: () => import(/* webpackChunkName: "page-product" */ './pages/ProductPage'),
    meta: { restricted: true },
    beforeEnter: AuthViewGuards
  },
  {
    path: '/products-showcase/packaging/:slug',
    name: 'PackagingPage',
    props: true,
    component: () => import(/* webpackChunkName: "page-packaging" */ './pages/PackagingPage'),
    meta: { restricted: true },
    beforeEnter: AuthViewGuards
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: () => import(/* webpackChunkName: "page-privacy" */ './pages/PrivacyPage')
  },
  {
    path: '/documents',
    name: 'DocumentsPage',
    component: () => import(/* webpackChunkName: "page-documents" */ './pages/DocumentsPage')
  },
  {
    path: '/documents/:slug',
    name: 'DocumentPage',
    props: true,
    component: () => import(/* webpackChunkName: "page-document" */ './pages/DocumentPage')
  },
  {
    path: '/virtual-tour',
    name: 'VirtualTourPage',
    component: () => import(/* webpackChunkName: "page-virtual-tour" */ './pages/VirtualTourPage')
  },
  {
    path: '/:slug',
    name: 'ModularPage',
    props: true,
    component: () => import(/* webpackChunkName: "page-modular" */ './pages/ModularPage')
  }
]

//
// System routes
//
if (!packageConfig.multilanguage || packageConfig.multilanguage === 'false') {
  // no multilanguage
  routes = routes.concat([
    {
      // matches /anyroute (if not found) and redirects it to /
      path: '*',
      redirect: '/'
    }
  ])
}

export default routes
