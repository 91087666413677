<template>
  <div>
    <p class="stagger">2/3</p>
    <h3 class="step-title mb-8 mb-md-0" v-html="title"></h3>
    <div>
      <ChoiceGroup
        required
        ref="input"
        name="contactReason"
        :choices="choices"
        choiceClasses="stagger"
      />
    </div>
    <div class="mb-6 stagger">
      <Button @click="$emit('next')" type="button" :disabled="!isValid">
        Next
      </Button>
    </div>
    <div class="stagger">
      <Cta action="button" label="Back" @click="$emit('prev')" reverse colorSchema="scientific"/>
    </div>
  </div>
</template>

<script>
import Splitting from 'splitting'
import { gsap } from 'gsap'
import Cta from '@/molecules/Cta'
import Button from '@/atoms/Button'
import ChoiceGroup from '@/atoms/ChoiceGroup'

export default {
  name: 'Step3',
  components: { ChoiceGroup, Button, Cta },
  data () {
    return {
      isValid: false,
      title: Splitting.html({ content: 'Why are you contacting us?', by: 'chars' }),
      choices: Object.freeze(['Marketing', 'Curiosity', 'Meet', 'Create a product', 'Collaboration'])
    }
  },
  mounted () {
    this.$nextTick(() => (this.isValid = this.$refs.input.isValid))
    this.$watch('$refs.input.isValid', (value) => (this.isValid = value))
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  methods: {
    enter (circleSize) {
      this.tl && this.tl.kill()
      this.tl = gsap.timeline()
      const inputs = this.$refs.input.$refs.inputContainer
      const angleStep = Math.PI * 2 / inputs.length
      const radius = circleSize / 2

      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i]
        const bb = input.getBoundingClientRect()

        this.tl.fromTo(input, {
          x: 0,
          y: 0
        }, {
          duration: 0.5,
          ease: 'circ.inOut',
          x: radius * Math.cos(angleStep * i) - bb.width / 2,
          y: radius * Math.sin(angleStep * i) - bb.height / 2
        }, 1.3 + 0.1 * i)
      }
    },
    exit () {
      this.tl && this.tl.kill()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep fieldset {
  @media (max-height: 769px) {
    padding-top: rem(20px);
  }
}

::v-deep .choice-button {

  @media (max-height: 769px) {
    transform: none !important;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} and (min-height: 770px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0, 0);

    label {
      border-radius: 50%;
      width: 130px;
      height: 130px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
      background: $color-bg;
      padding: rem(10px);
      font-size: rem(16px);
      font-family: 'PP Woodland Regular';
    }
  }
  @include breakpoint('sm-and-down') {
    transform: translate(0, 0) !important;
  }
}
</style>
