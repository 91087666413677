<template>
  <div>
    <h1 class="step-title" v-html="title"></h1>
    <p class="stagger">Thank you for writing us,<br/>we will get back to you soon ;)</p>
    <div class="mt-16 stagger">
      <Cta action="button" label="Continue to navigate" @click="$emit('close-modal')" colorSchema="scientific"/>
    </div>
  </div>
</template>

<script>
import Splitting from 'splitting'
import Cta from '@/molecules/Cta'
export default {
  name: 'Step6',
  components: { Cta },
  data () {
    return {
      title: Splitting.html({ content: 'All Done!', by: 'chars' })
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  width: 90%;
  margin: rem(16px) auto;
}
</style>
